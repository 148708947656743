<script setup lang="ts">
const route = useRoute()
const app = useAppConfig()
import '~/assets/css/whatsapp.css'
/**
 * Global head configuration
 * @see https://nuxt.com/docs/getting-started/seo-meta
 */
useHead({
  title: () => route.meta?.title ?? '',
  titleTemplate: (titleChunk) => {
    return titleChunk
      ? `${titleChunk} - ${app.tairo?.title}`
      : `${app.tairo?.title}`
  },
  htmlAttrs: {
    lang: 'pt-BR',
    dir: 'ltr',
  },
  link: [
    {
      rel: 'apple-touch-icon',
      sizes: '180x180',
      href: '/apple-touch-icon.png',
    },
    {
      rel: 'icon',
      type: 'image/svg',
      sizes: '32x32',
      href: '/favicon.svg',
    },
    {
      rel: 'icon',
      type: 'image/svg',
      sizes: '16x16',
      href: '/favicon.svg',
    },
    {
      rel: 'manifest',
      href: '/site.webmanifest',
    }
],

  meta: [
    {
      name: 'description',
      content: () =>
        route.meta.description
        ?? 'Integra Flux - Envie mensagens de SMS em massa, recupere carrinhos abandonados, valide números de telefone. SMS Homologado',
    },
    {
      name: 'instagram:card',
      content: 'summary_large_image',
    },
    {
      name: 'instagram:site',
      content: '@integraflux',
    }
  ],
})

// sleep 2 seconds
const sleep = async () => {
  console.log('Iniciando a Integra Flux...')
  await new Promise((resolve) => setTimeout(resolve, 2000))
}

// sleep 2 seconds
sleep()

</script>

<template>
  <div>
    <a target="_blank" class="whatsapp btn" href="https://wa.me/551132808396?text=Estou precisando de uma ajuda"><i class="icon"><img data-perfmatters-preload="" src="/whatsapp.svg" alt="Atendimento por WhatsApp"></i>Falar com suporte</a>
    
    <NuxtLayout >
      <NuxtLoadingIndicator color="rgb(var(--color-primary-500))" />
      <NuxtPage  />
    </NuxtLayout>
  </div>
</template>